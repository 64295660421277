.columnWith > thead > tr > th,
.columnWith > tbody > tr > td,
{
  min-width: 7rem;
}

.actionButton {
  width: 10rem;
}

@media only screen and (max-width: 991px) {
  .actionButton {
    width: 90px;
  }
}