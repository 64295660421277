@import "./styles/kiwanis-colors.scss";

$sidebar-bg-color: $kiwanis-blue-color;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: $kiwanis-light-blue-color;
$highlight-color: $white-color;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

$primary: $kiwanis-blue-color;
$secondary: $kiwanis-gray-color;
$info: $kiwanis-light-blue-color;

@import "~bootstrap/scss/bootstrap";
@import '~react-pro-sidebar/dist/scss/styles.scss';

@font-face {
  font-family: 'Staatliches';
  src: local('Staatliches'), url(./fonts/Staatliches-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Arial', 'sans-serif';
  font-weight: 400;
  line-height: 1.6;
  font-size: 1em;
}

/************************************************
*	Global
************************************************/

p {
  word-wrap: break-word;
}

p:empty {
  display: none;
}

/************************************************
*	Headings
************************************************/

h1, h2, h3, h4, h5, h6 {
  color: #333333;
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Arial-Bold', 'Arial', 'sans-serif';
  font-weight: 400;
  line-height: 1.4;
  word-wrap: break-word;
  margin: 4px 0px;
  padding: 4px 0px;
}

h1 {
  font-size: 2.6em;
  letter-spacing: -1px;
}

h2 {
  font-size: 2.4em;
  letter-spacing: -1px;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.6em;
}

h5 {
  font-size: 1.4em;
}

h6 {
  font-size: 1.1em;
}

h1.headline {
  font-family: 'Staatliches', 'sans-serif';
  font-size: 3em;
}

.headline {
  font-size: 3em;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: -1px;
  word-wrap: break-word;
  margin: 0;
  padding: 0 0 12px 0;
}

h2 {
  font-size: 2.4em;
  letter-spacing: -1px;
}

/************************************************
*	React Pro Sidebar
************************************************/


#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    min-height: 35px;
    height: 35px;
    background-image: url('/assets/blue-stripe-pattern.png');
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 3px;
      background: rgba(255, 255, 255, 0.05);
      color: $kiwanis-light-blue-color;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 5px;
        font-size: 13px;
      }

      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    header {
      margin-bottom: 40px;

      h1 {

      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;

      a {
        text-decoration: none;
        color: #888;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;

    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;

    > span {
      margin-left: 10px;
    }
  }


  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 2em;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.form-label {
  font-weight: bold;
}

.required-asterisk{
  color: $kiwanis-gold-color;
  font-size: 1.1em;
  margin-left: 3px;
}

.cursor_pointer {
  cursor: pointer;
}

.ReactModal__Content:focus {
  outline: none;
}

.ReactModal__Content:focus-visible {
  outline: none;
}

/************************************************
 *	Mobile Devices							*
 ************************************************/
